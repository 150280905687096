<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.EXP.FUELS_EXPENSES") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-warning font-weight-bolder mr-2"
            @click="handleExport"
          >
            Експорт
          </button>
          <router-link
            v-permission="['fuel-expenses-create']"
            to="add"
            class="btn btn-light-success font-weight-bolder"
            ><i class="flaticon2-plus"></i>
            {{ $t("LABELS.ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col class="pb-0" :md="4">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-to-input"
                  type="text"
                  name="date_from"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
                  :validateState="{}"
                  v-model="filters.dateFrom"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group id="date-from-group" label-for="date-from-input">
                <FormDatepicker
                  id="date-from-input"
                  type="text"
                  name="date_to"
                  :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
                  :validateState="{}"
                  v-model="filters.dateTo"
                ></FormDatepicker>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="4">
              <b-form-group
                id="gasStations-group"
                label-for="gasStations-input"
              >
                <FormSelect
                  id="gasStations-input"
                  :placeholder="$t('MENU.ITEM.TANKS.GAS_STATION')"
                  type="select"
                  name="gasStations"
                  :options="gasStations"
                  clearable="true"
                  v-model="filters.gasTankStationId"
                ></FormSelect>
              </b-form-group>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingFuelExpense"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="elevation-1 px-6"
        >
          <template v-slot:[`item.invoiceDate`]="{ item }">
            {{ item.invoiceDate | moment("DD.MM.YYYY") }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-end">
              <span v-if="checkIfUserHasPermission(['fuel-expenses-update'])">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      color="primary"
                      fab
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="handlePrintClick(item.id)"
                    >
                      <v-icon>mdi-view-list</v-icon>
                    </v-btn>
                  </template>
                  <span>Разпределение</span>
                </v-tooltip>
              </span>
              <router-link
                v-if="checkIfUserHasPermission(['fuel-expenses-update'])"
                :to="{
                  name: 'edit-fuel-expenses',
                  params: { id: item.id }
                }"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("LABELS.EDIT") }}</span>
                </v-tooltip>
              </router-link>
              <template v-else>Няма права</template>
            </div>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";

import SearchTools from "@/core/components/table/SearchTools.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";

import {
  FETCH_FUEL_EXPENSES,
  GENERATE_FUEL_EXPENSE_PDF_URL,
  GENERATE_EXPORT_FUEL_EXPENSE_ROWS_URL
} from "@/modules/fuel-expenses/store/fuelexpenses.module";
import { FETCH_GAS_STATIONS } from "@/modules/gas-stations/store/gasstation.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  name: "FuelExpensesList",
  mixins: [permissionMixin],
  components: {
    SearchTools,
    FormDatepicker,
    FormSelect
  },
  data() {
    return {
      isPageLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      options: {
        sortBy: ["invoiceDate"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 30
      },
      filters: {
        dateFrom: null,
        dateTo: null,
        gasTankStationId: null
      },
      rowsPerPageItems: [10, 20, 30, 40, 50],
      gasStations: [],
      headers: [
        { text: this.$i18n.t("FORM_LABELS.DATE"), value: "invoiceDate" },
        {
          text: this.$i18n.t("MENU.ITEM.TANKS.GAS_STATION"),
          value: "gasStation"
        },
        {
          text: this.$i18n.t("FORM_LABELS.INVOICE_NUMBER"),
          value: "invoiceNumber"
        },
        {
          text: this.$i18n.t("FORM_LABELS.VALUE_WITH_VAT"),
          value: "totalWithVat"
        },
        {
          align: "right",
          text: this.$i18n.t("MENU.ITEM.ACTIONS"),
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.EXP.FUELS_EXPENSES"),
        route: { name: "list-fuel-expenses" }
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") }
    ]);
    this.$store.dispatch(FETCH_GAS_STATIONS).then(data => {
      this.gasStations = data.data.items;
    });
  },
  computed: {
    ...mapGetters(["isLoadingFuelExpense"]),
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    },
    tableHeaders() {
      let vm = this;
      return vm.headers;
    }
  },
  methods: {
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      Object.keys(vm.filters).forEach(key => {
        vm.filters[key] = null;
      });
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = vm.$url.transformParams(params);
      vm.$store
        .dispatch(FETCH_FUEL_EXPENSES, apiParams)
        .then(data => {
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found"
            });
            this.$router.push({ name: "list-fuel-expenses" });
          }
        });
    },
    handlePrintClick(id) {
      this.$store.dispatch(GENERATE_FUEL_EXPENSE_PDF_URL, id);
    },
    handleExport() {
      this.$store.dispatch(
        GENERATE_EXPORT_FUEL_EXPENSE_ROWS_URL,
        this.$url.transformParams({
          ...this.filters,
          query: this.searchQuery
        })
      );
    }
  }
};
</script>
